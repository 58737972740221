<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value: number
    initialValue?: number
    /** Threshold to animate. If the difference between `value` and `initialValue` is smaller than this, no animation will occur */
    animationThreshold?: number
    decimals?: number
    duration?: number
    ease?: "linear" | "in-out"
  }>(),
  {
    initialValue: 0,
    animationThreshold: 2,
    decimals: 0,
    duration: 1500,
    ease: "linear",
  },
)
const shouldAnimate = computed(
  () => Math.abs(props.value - props.initialValue) >= props.animationThreshold,
)
const animationFrameRequest = ref<number | null>(null)
const animationStartTime = ref(0)
const oldDisplayValue = ref(props.initialValue)
const displayValue = ref(shouldAnimate.value ? props.initialValue : props.value)

const { locale } = useI18n()
const numberFormat = computed(() => {
  return new Intl.NumberFormat(locale.value.replace("_", "-"), {
    maximumFractionDigits: props.decimals,
    minimumFractionDigits: props.decimals,
  })
})

const formattedDisplayValue = computed(() =>
  numberFormat.value.format(displayValue.value),
)

const stopAnimation = () => {
  if (animationFrameRequest.value !== null) {
    cancelAnimationFrame(animationFrameRequest.value)
  }
}

const inOut = (k: number) => {
  if ((k *= 2) < 1) return -0.5 * (Math.sqrt(1 - k * k) - 1)
  return 0.5 * (Math.sqrt(1 - (k -= 2) * k) + 1)
}

const applyEasing = (value: number) => {
  switch (props.ease) {
    case "linear":
      return value
    case "in-out":
      return inOut(value)
    default:
      throw `Unknown easing method: ${value}`
  }
}

const animationTick = (timestamp: number) => {
  if (animationStartTime.value === 0) {
    animationStartTime.value = timestamp
  }

  const animationPercentage = applyEasing(
    Math.min((timestamp - animationStartTime.value) / props.duration, 1),
  )

  const valueDiff = props.value - oldDisplayValue.value

  displayValue.value = oldDisplayValue.value + animationPercentage * valueDiff

  if (timestamp < animationStartTime.value + props.duration) {
    animationFrameRequest.value = requestAnimationFrame(animationTick)
  } else {
    animationFrameRequest.value = null
  }
}

const startAnimation = () => {
  stopAnimation()
  oldDisplayValue.value = displayValue.value
  animationStartTime.value = 0
  animationTick(animationStartTime.value)
}

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (
      animationFrameRequest.value === null &&
      Math.abs(oldValue - newValue) <= props.animationThreshold
    ) {
      oldDisplayValue.value = displayValue.value = newValue
    } else {
      startAnimation()
    }
  },
)

onMounted(() => {
  if (!shouldAnimate.value) {
    displayValue.value = props.value
    return
  }
  oldDisplayValue.value = props.initialValue
  displayValue.value = props.initialValue
  startAnimation()
})

onBeforeUnmount(() => {
  stopAnimation()
})
</script>

<template>
  <span>{{ formattedDisplayValue }}</span>
</template>
